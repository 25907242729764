import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppRoutes from './Routes';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const stripePromise = loadStripe('pk_test_51MshQZKjr0nDbauHpoQZY7pvEDomZcwKjpZMUKL3vtdXnWeg7jxyNSbb1hFqv7M2YhqbFFhohrhV0U0HNTMng7xY00a6RAKEFJ')


root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary>
        <Elements stripe={stripePromise}>
          <AppRoutes />
        </Elements>
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
