import React, { FunctionComponent, useEffect, useRef } from 'react'
import { ChatImage, ChatMessagesWrapper } from './Messages.styles'
import ChatConversationHeader from './ChatConversationHeader'
import MessageInputField from './MessageInputField'
import MessageBubble from './MessageBubble'
import { ChatMessageType, IPagination, ParticipantType } from '../../../utils/interfaces'
import uiStore from '../../../stores/uiStore'
import Text from '../../../components/Text'
import { colors } from '../../../styles/_var'
import Box from '../../../components/Box'
import { WelcomeChatMessage } from '../../../stores/chatStore'
import { userStore } from '../../../stores/userStore'
import useIsMobile from '../../../hooks/useIsMobile'


type ChatConversationsProps = {
  participant?: ParticipantType,
  messages: ChatMessageType[],
  paginationInfo: IPagination,
  onSend: (message: string, type?: number) => void,
  onDelete: () => void,
  goBack: () => void,
  loadOlder: () => void,
  isBaerifyAdminChat?: boolean
}

const ChatConversations: FunctionComponent<ChatConversationsProps> = ({
  participant,
  messages,
  paginationInfo,
  onSend,
  onDelete,
  goBack,
  loadOlder,
  isBaerifyAdminChat
}) => {
  const isMobile = useIsMobile()
  const chatMessageContainer = useRef<null | HTMLDivElement>(null)
  const input = React.useRef<HTMLInputElement>(null);
  const openImage = (imgsrc: string) => {
    uiStore.openDialog({
      dialogBody: <ChatImage src={imgsrc} alt='chat-message' style={{marginLeft: 0}} />
    })
  }

  useEffect(() => {
    setTimeout(() => {
      if (chatMessageContainer.current) {
        chatMessageContainer.current.scrollTop = chatMessageContainer?.current?.scrollHeight;
      }
  
      if (input.current) {
        const chatTextinputs = input.current?.getElementsByTagName('input') || []
        if (chatTextinputs[0]) chatTextinputs[0].focus()
      }
    }, 100)
  }, [])

  useEffect(() => {
    if (isMobile) {
      uiStore.setFooterVisibility(false)
    }

    return () => {
      uiStore.setFooterVisibility(true)
    }
    // eslint-disable-next-line
  }, [])

  const createdAt = userStore?.item?.created_at || ''

  return (
    <>
      <ChatConversationHeader
        onDelete={onDelete}
        participant={participant}
        goBack={goBack}
      />
      <ChatMessagesWrapper ref={chatMessageContainer}>
        {(paginationInfo.has_next && !!participant && !isBaerifyAdminChat) && (
          <Box alignCenter>
            <Text
              variant='regular'
              link
              color={colors.primary}
              mb={2}
              onClick={loadOlder}
              inline
            >
              fetch older...
            </Text>
          </Box>
        )}
        {messages.map((message, i) => (
          <MessageBubble
            onOpenImage={openImage}
            participant={participant?.id === message.participant ? participant : undefined}
            chatMessage={message}
            key={i} 
          />
        ))}
        {isBaerifyAdminChat && (
          <MessageBubble 
            onOpenImage={openImage}
            participant={participant}
            chatMessage={{...WelcomeChatMessage, created_at: createdAt}}
          />
        )}
      </ChatMessagesWrapper>
      {(!!participant && !isBaerifyAdminChat) && (
        <MessageInputField
          onSend={onSend}
          ref={input} />
      )}
    </>
  )
}

export default ChatConversations
