import React from 'react'
import crypto from 'crypto-js';
import axios, { AxiosResponse } from 'axios';
import ReactGA from "react-ga4";
import moment from 'moment';
import Auth from './auth';
import {
  RequestType,
  RequestOptions,
  IUploadImage,
  ComponentSpacing
} from './interfaces';
import { regexPatterns, CreditCardIcons, errorMessage, AWS_BASE_URL, routes } from './constants';
import { size, spacing } from '../styles/_var';
import { getPresignedUrlRequest } from './apiRequests';
import { userStore } from '../stores/userStore';
import { Location } from 'react-router-dom';


declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_ENCRYPTION_KEY: string,
      REACT_APP_IV: string,
      REACT_GOOGLE_API_KEY: any,
      REACT_APP_NODE_ENV: 'development' | 'staging' | 'production',
      REACT_APP_GOOGLE_OAUTH_CLIENT_KEY: string,
      REACT_APP_PAYSTACK_PUBLIC_KEY: string,
      REACT_APP_APP_SYNC_REGION: string,
      REACT_APP_APP_SYNC_AUTH_TYPE: string,
      REACT_APP_APP_SYNC_API_KEY: string,
      REACT_APP_APP_SYNC_ENDPOINT: string,
      REACT_APP_DD_CLIENT_KEY: string,
      REACT_APP_GA_TRACKING_CODE: string
    }
  }

  interface Window {
    DD_LOGS: any
  }
}

interface IFetchPayload {
  url: string,
  method?: RequestType,
  body?: any,
  headerOptions?: any,
  useToken?: boolean,
  useBaseUrl?: boolean,
}

interface IUploadToS3 {
  filename: string,
  fileUrl: string,
  presignedUrl?: any,
  imageElementsToRefresh?: any
}

export const parseToNumber = (value: string): number | string => {
  if (!value) return '';
  return Number(value.replace(/[^\d]/g, ''));
}

export const formatToCurrency = (value: string): string => {
  if (!value) return '';

  let newValue = String(value);
  newValue = newValue.replace(/[^\d]/g, '')

  if (newValue.length < 4) return `N${newValue}`;

  return toCurrency(Number(newValue), false, true);
}

export function beautifyTime(rawDate: string) {
  return moment(rawDate).format('hh:mm a')
}

export function beautifyDate(rawDate: string | Date) {
  return moment.utc(rawDate).local().format('Do of MMM, YYYY');
}

export function maskCardNumber(cardNumber: string): string {
    return `**** **** **** ${cardNumber.substr(-4)}`;
}

export function toCurrency(value: number, showDecimal:boolean = true, showCurrency:boolean = true) {
  let formattedCurrency = `${showCurrency ? "$" : ""}${Number(value === -1 ? 0 : value).toLocaleString()}${
    showDecimal ? ".00" : ""
    }`;

  if (formattedCurrency.includes('-')) {
    formattedCurrency = `-${formattedCurrency.replace('-', '')}`
  }

  return formattedCurrency
}

export function capitalize(text: string): string {
  return text[0].toUpperCase() + text.slice(1);
}

export function camelCasize(text: string): string {
  if (!text.includes(' ')) return text.toLowerCase();
  const [firstWord, secondWord] = text.split(' ');

  return firstWord.toLowerCase() + capitalize(secondWord);
}

export function isEmail(value: string): boolean {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
}

export function isPhoneNo(phoneNumber: string): boolean {
  return regexPatterns.phone.test(phoneNumber)
}

export function parseToPhone(rawPhone: string): string {
  return rawPhone ? `+234${rawPhone.replace(/^(\+?234)?0?/, '')}` : ''
}

export function parseUsername(username: string): string {
  if (isPhoneNo(username)) {
    return parseToPhone(username)
  }

  return username
}

export function isPassword(password: string): string | undefined {
  return (password || '').length > 5 ? undefined : 'Password is too short';
}

export const isSame = (password: string ) => (confirmPassword: string): string | undefined => {
  return password === confirmPassword ? undefined : 'Password does not match'
}

export function encrypt(text: string): string {
  const cipherText = crypto.AES.encrypt(text, process.env.REACT_APP_ENCRYPTION_KEY).toString()
  return cipherText
}

export function decrypt(cipherText: string): string {
  const bytes = crypto.AES.decrypt(cipherText, process.env.REACT_APP_ENCRYPTION_KEY)
  return bytes.toString(crypto.enc.Utf8)
}

export function fetch(fetchPayload: IFetchPayload): Promise<AxiosResponse<any>> {
  const { 
    url,
    method="GET",
    body,
    headerOptions,
    useBaseUrl=true,
    useToken=true 
  } = fetchPayload

  const baseUrls: any = {
    development: `http://${window.location.hostname}:8000/api`,
    staging: process.env.REACT_APP_STAGING_API,
    production: process.env.REACT_APP_PROD_API
  }
  if (useBaseUrl) {
    axios.defaults.baseURL = baseUrls[process.env.REACT_APP_NODE_ENV]
  } else {
    axios.defaults.baseURL = ''
  }

  const generateRequestOptions = () => {
      const userToken:string | null = Auth.fetchToken();
      const options:RequestOptions = { url, method: method };

      if (body) options.data = body;

      if (userToken && useToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
      } else {
        delete axios.defaults.headers.common['Authorization'];
      }

      return { ...options, ...(headerOptions || {}) };
  }
  const requestOptions = generateRequestOptions();

  return new Promise((resolve, reject) => {
    axios
      .request(requestOptions)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        if (tokenExpired(error)) {
          userStore.logout(() => window.location.href = routes.LOGIN)
        }
        reject(error)
      });
  })
}

export const retrieveErrorMessage: any = (errorObject: any): string => {
  const serverResponse = errorObject?.response?.data || {};
  const errors = Object.values(serverResponse)[0];

  if (Array.isArray(errors)) {
    return `${errors}`;
  }

  return serverResponse.message || serverResponse.detail || errorMessage;
}

export const max = (number: number) => (value: string) => {
  if (!value) return '';
  if (value.length > number) return value.substring(0, number-1)
  return value;
}

export const formatPhoneNumber = (rawPhone: string): string => {
  let phone = rawPhone;

  if (!phone || phone.trim() === '') return phone;

  if (phone.length > 7) {
    if (phone.startsWith('+234') || phone.startsWith('234') || phone.startsWith('+2340')) {
      phone = phone.replace(/\+?2340?/, '');
    }
  
    if (phone.startsWith('0')) {
      phone = phone.replace(/^0/, '');
    }
  }

  return phone.replace(/[^\d]/g, '');
}

export const formatToNumber = (value: string): string => {
  if (!value || value.trim() === '') return value;
  return value.replace(/[^\d]/g, '');
}

export const formatToFloat = (value: string): string => {
  if (!value || value.trim() === '') return value;
  if (/^\d*\.?\d*$/.test(value)) {
    return value
  } else {
    return value.substring(0, value.length - 1)
  }
}

export const formatToPhone = (rawPhone: string): string => {
  if (!rawPhone || rawPhone.trim() === '') return rawPhone;
  return `+234${rawPhone.replace(/\+?2340?/, '')}`;
}

export var formatToUsername = (username: string) => {
  if (username) return username.replace(/ /g,  '').toLowerCase()
  return ''
}

export const isMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)
}

export const getAddressComp = (addressObject: any, compName: string): string => {
  return (addressObject.address_components
    .find((component: any) => 
      component.types.some((e: any) => e.includes(compName))
    ) || { long_name: ''}).long_name
}

export const addPaystackCommission = (amount: number = 0): number => {
  let commission = 0

  if (amount > 2000) {
    commission += 100 + (0.015 * amount)
  } else {
    commission += (0.015 * amount)
  }

  if (commission > 2000) commission = 2000

  return Math.round(amount + commission)
}

export const getAppSettings = (): any => {
  const settings = sessionStorage.getItem('app_settings');
  return settings ? JSON.parse(decrypt(settings)) : null
}

export const padToLengthWithZero = (toLength:number, str?: string) => {
  let res = `${str}`;
  if (!res || res.length >= toLength) return str
  const numberOfZeros = toLength - res.length
  for (let i=0; i<numberOfZeros; i++) res = '0' + res

  return res;
}

export const isMobileScreen = () => {
  return window.screen.width <= Number(size.sm.replace('px', ''));
}

export const pick = (payload: any, fields: string[]) => {
  const results: any = {}

  fields.forEach(field => {
    if (field in payload) results[field] = payload[field]
  })
  
  return results
}

export const exclude = (payload: any, fieldsToExclude: string[]) => {
  const results: any = {}

  Object.keys(payload).forEach(field => {
    if (!fieldsToExclude.includes(field)) results[field] = payload[field]
  })

  return results
}

export const sanitizeUserRegisterPayload = (payload: any) => {
  const [first_name, last_name] = payload.full_name.split(' ')
  return {
    ...payload,
    phone_number: parseToPhone(payload.phone_number),
    first_name,
    last_name
  }
}

export const getPaystackReference = (username: string) => {
  return `${+(new Date())}_${username.substr(1)}`
}

export const uploadToS3 = async ({ filename, fileUrl, presignedUrl, imageElementsToRefresh }: IUploadToS3) => {
  let signedUrl = presignedUrl

  try {
    if (!signedUrl) {
      const response = await getPresignedUrlRequest(filename)
      signedUrl = response.data.presigned_url
    }

    const formData = new FormData()
    const response = await fetch({
      url: fileUrl,
      useBaseUrl: false,
      useToken: false,
      headerOptions: { responseType: 'blob' }
    })
    const fileBlob = response.data
    const { url, fields } = signedUrl

    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key]);
    });

    formData.append('file', fileBlob, filename)
    await fetch({
      url,
      method: 'POST',
      body: formData,
      useBaseUrl: false,
      useToken: false,
    });

    if (imageElementsToRefresh) {
      refreshElements(imageElementsToRefresh, fileUrl)
    }

  } catch (e) {
    return new Error('Failed to upload image')
  }
}

/** Hacks to reflect uploads after uploading avatar to s3 */
export const refreshElements = (elementsToRefresh: any, fileUrl?: string) => {
  if (elementsToRefresh.length) {
    let counter = 0;
    for (;  counter < elementsToRefresh.length; counter++) {
      refreshElement(elementsToRefresh[counter], fileUrl)
    }
  } else {
    refreshElement(elementsToRefresh, fileUrl)
  }
}

export const refreshElement = (element: any, fileUrl?: string) => {
  if (element.src) {
    element.src = ""
    element.src = fileUrl
  } else if (element.style) {
    element.style.backgroundImage = ""
    element.style.backgroundImage = `url("${fileUrl}")`
  }
}
/** End if hack */

export const generateFileUploadPath = (filekey: string, folderPath: string) => {
  let uploadPath = folderPath
  uploadPath += `${filekey}_${+(new Date())}.jpg`

  return [uploadPath, `${AWS_BASE_URL}/${uploadPath}`]
}

export const uploadImage = async ({ key, imageUrl, folderPath, async=true, imageElementsToRefresh }: IUploadImage) => {
  if (!imageUrl) return

  const [filename, s3Path] = generateFileUploadPath(key, folderPath)

  if (async) {
    uploadToS3({ filename, fileUrl: imageUrl, imageElementsToRefresh })
  } else {
    await uploadToS3({ filename, fileUrl: imageUrl, imageElementsToRefresh })
  }

  return s3Path
}

export const getPercent = (value: number, percent: number) => value * (percent/100)

export const processError = (errorObject: any) => {
  try {
    console.error(retrieveErrorMessage(errorObject))
  } catch {}
}

export const toUTC = (value: Date | string) => {
  if (!value) return ''
  if (typeof value === 'string') {
    return new Date(value).toISOString()
  }

  return value.toISOString()
}

export const appendQueryString = (url: string, payload: any) => {
  let qs = ''

  Object.keys(payload).forEach(key => {
    qs += !qs && !url.includes('?') ? '?' : '&'
    qs += `${key}=${payload[key]}`
  })

  return `${url}${qs}`
}

export const getFromQueryString = (queryString: string, field: string, defaultValue?: string) => {
  const matcher = new RegExp(`${field}=([^&]*)`, 'i')
  return queryString.match(matcher)?.[1] || defaultValue
}

export const wordify = (word: string) => word.replace(/_/g, ' ')

export const mapToProps = (mappings: any, Container: any) => {
  return (props: any) => {
    return React.createElement(Container, {...props, ...mappings})
  }
}

export const plurify = (singular: string, plural: string, count: number) => {
  return `${count} ${count > 1 ? plural : singular}`
}

export const getCurrency = () => String.fromCharCode(8358)

export const setPageTitle = (title: string) => {
  let titleText = 'Baerify'

  if (title && title !== 'undefined') {
    titleText += `:: ${title}`
  }
  document.title = titleText
}

export const sanitizeCardType = (card_type: string) => {
  const creditCardKeys = Object.keys(CreditCardIcons)
  let sanitizedCardType = card_type.toLowerCase()
  return creditCardKeys.find((key: string) =>  sanitizedCardType?.includes(key))
}

export const getStyleSpacing = ({ mt, mb, mr, ml }: ComponentSpacing): any => {
  const style: any = {}
  const spacingKeys = Object.keys(spacing)

  if (mt && mt < spacingKeys.length) {
    style.marginTop = (spacing as any)[spacingKeys[mt - 1]]
  }

  if (mb && mb < spacingKeys.length) {
    style.marginBottom = (spacing as any)[spacingKeys[mb - 1]]
  } 
  
  if (mr && mr < spacingKeys.length) {
    style.marginRight = (spacing as any)[spacingKeys[mr - 1]]
  }
  
  if (ml && ml < spacingKeys.length) {
    style.marginLeft = (spacing as any)[spacingKeys[ml - 1]]
  }

  return style
}

export const makeid = (length: number = 5) => {
  let counter = 0;
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (; counter < length; counter++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export const isObject = (obj: any) => {
  return obj !== null && !Array.isArray(obj) && typeof obj === 'object'
}

export const getDuration = (date: Date | string) => {
  const start = moment.now() 
  const end = moment.utc(date).local()
  return moment.duration(end.diff(start))
}

export const humanize = (date: Date | string, prefix: boolean=true) => {
  return getDuration(date).humanize(prefix)
}

export const humanizeAbbr = (date: Date | string) => {
  try {
    const humanizedTime = humanize(date, true)
    const [number, desc] = humanizedTime.split(' ')
    if (Number.isNaN(+number)) return humanizedTime
    return `${number}${desc[0]}`
  } catch {
    return ''
  }
}

export const getFilenameFromS3Url = (s3url?: string) => {
  if (!s3url) return null
  const regex = /amazonaws.com\/(.*)/i
  const match = s3url.match(regex)

  return match ? match[1] : match
}

export const isWebsocketDisconnectedError = (errorPayload: any) => {
  const errors = errorPayload?.error.errors
  if (!Array.isArray(errors)) return false

  return errors.some(e => e?.message.toLowerCase() === 'connection closed')
}

export const tokenExpired = (error: any) => {
  const response = error?.response
  return response?.data?.detail === 'Signature has expired.' || response?.status === 403
}

export var isDev = process.env.REACT_APP_NODE_ENV === 'development'

export var isProd = process.env.REACT_APP_NODE_ENV === 'production'

export const initializeGA = () => {
  if (isDev) return
  try {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
  } catch (e) {
    console.log(e)
  }
}

export const trackPageView = (location: Location) => {
  if (isDev) return
  try {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  } catch (e) {
    console.log(e)
  }
}

export const gaSendEvent = (eventPayload: any) => {
  if (isDev) return
  try {
    ReactGA.event(eventPayload)
  } catch (e) {
    console.log(e)
  }
}

export const getThumbnailUrl = (pictureUrl: string) => {
  const split = pictureUrl.split(/\.(?=\w+$)/)
  return `${split[0]}-small.${split[1] || ''}`
}