import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from "react-router-dom";
import Box from '../../../components/Box'
import Input from '../../../components/Input'
import SimpleForm, { IActionButton } from '../../../components/SimpleForm'
import Text from '../../../components/Text'
import { colors } from '../../../styles/_var'
import { formatToUsername } from '../../../utils/helpers'
import validators from '../../../utils/validators'
import Checkbox from '../../../components/Checkbox'
import Link from '../../../components/Link'
import { routes } from '../../../utils/constants'
import PhoneNoInput from '../../../components/PhoneNoInput'
import { registerStore } from '../../../stores/userStore'
import Auth from '../../../utils/auth'
import AuthLayoutHeader from '../../../components/AuthLayoutHeader'
import GoogleAuthButton from '../../../components/GoogleAuthButton';


const Register = () => {
  const navigate = useNavigate()
  const [phoneExtension, setPhoneExtension] = useState('+234')
  const registerFields: any = [
    {
      name: 'full_name',
      label: 'Full Name',
      component: Input,
      max_length: 50,
      validate: validators.required
    },
    {
      name: 'username',
      label: 'Username (No spaces allowed)',
      parse: formatToUsername,
      component: Input,
      max_length: 50,
      validate: validators.required,
    },
    {
      name: 'email',
      label: 'Email',
      component: Input,
      max_length: 40,
      validate: validators.isEmail
    },
    {
      name: 'phone_number',
      component: PhoneNoInput,
      max_length: 50,
      validate: validators.required,
      extension: phoneExtension,
      onExtensionChange: (e: any) => {
        setPhoneExtension(e.target.value)
      }
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      component: Input,
      max_length: 50,
      validate: validators.required
    },
    {
      name: 'tandc',
      label: <span>Agree to our <a style={{color: colors.primary}} href='/terms-and-conditions' target='_blank'>terms of use.</a></span>,
      component: Checkbox,
      type: 'checkbox',
      className: 'tandc',
      validate: validators.required
    }
  ]

  const actionButtons: IActionButton[] = [
    {
      label: 'NEXT',
      loading: registerStore.loading,
      isSubmit: true
    }
  ]

  const submitForm = (data: any) => {
    const userPayload = preparePayload(data, phoneExtension)
    registerStore.makeRequest(userPayload, { 
      onRequestComplete(serverData: any) {
        Auth.saveUserDetails(serverData.results)
        setTimeout(() => navigate(routes.DASHBOARD), 400)
      }
    })
  }

  return (
    <>
      <AuthLayoutHeader 
        title='Register'
        subtitle='Fill the details below to get started'
      />
      <SimpleForm
        formFields={registerFields}
        submitForm={submitForm}
        actionButtons={actionButtons}
      />
      <GoogleAuthButton buttonText='Sign up with Google' />
      <Box alignCenter mt={1}>
        <Text variant='small' center inline>Already an account? </Text> {""}
        <Link to={routes.LOGIN}>
          <Text variant='small' weight='bold' inline center color={colors.primary}>
            Log in
          </Text>
        </Link>
      </Box>
    </>
  )
}


export default observer(Register)

const preparePayload = (data: any, phoneExtension: string) => {
  const [first_name, last_name] = data.full_name.split(' ')
  const phone_number = phoneExtension + '-' + data.phone_number

  return {
    ...data,
    first_name,
    last_name,
    phone_number
  }
}